var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calculator" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Billing Dispute",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "/new"
        }
      }),
      _c("transition", { attrs: { name: "slide-right" } }, [
        !_vm.toggleDispute
          ? _c("div", { staticClass: "container-fluid pt-70 pt-md-98" }, [
              _c("div", { staticClass: "card" }, [
                _c(
                  "div",
                  {
                    staticClass: "card__title",
                    staticStyle: { "justify-content": "center !important" }
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "fs20 lh24 fw500 align-center" },
                      [_vm._v(" Step 1 of 2 ")]
                    )
                  ]
                ),
                _c("div", { staticClass: "card__title first-title" }, [
                  _c("div", [
                    _c("span", { staticClass: "fs18 lh24 fw500" }, [
                      _vm._v(
                        "Upload Dispute Detail for Invoice Number : " +
                          _vm._s(_vm.ref_number)
                      )
                    ])
                  ]),
                  _c(
                    "div",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          attrs: { variant: "tertiary w-sm-100" },
                          on: { click: _vm.downloadToast }
                        },
                        [
                          _c("i", { staticClass: "icon-download mr-11" }),
                          _vm._v(" EXCEL SAMPLE ")
                        ]
                      )
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "card__content" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-4 pb-md-0 pb-32" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "rounded border text-center d-inline-block w-sm-100"
                            },
                            [
                              _vm.form.file
                                ? _c("img", {
                                    staticClass: "p-44",
                                    attrs: {
                                      src: require("@/assets/svg/document-uploaded.svg")
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "p-44",
                                    attrs: {
                                      src: require("@/assets/svg/document.svg")
                                    }
                                  })
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-8 d-flex flex-column justify-content-center"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "d-block fs16 lh19 mb-12 fw500" },
                              [_vm._v(" Disputed Excel ")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-block fs14 lh20 mb-20 text-gray-600"
                              },
                              [
                                _c("strong", [_vm._v("Note:")]),
                                _vm._v(
                                  " In weight column please enter claimed weight. "
                                )
                              ]
                            ),
                            _c("b-form-file", {
                              ref: "id-file-input",
                              staticClass: "d-none",
                              attrs: { plain: "", accept: ".xlsx" },
                              on: { input: _vm.uploadExcel },
                              model: {
                                value: _vm.form.file,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "file", $$v)
                                },
                                expression: "form.file"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "w-sm-100" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-sm-100 mr-0 mr-md-8",
                                    attrs: { variant: "tertiary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.popInput("id-file-input")
                                      }
                                    }
                                  },
                                  [_vm._v(" Choose File ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("div", { attrs: { id: "preview" } }),
                          _vm.form.file == null
                            ? [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-block text-gray-600 mt-16 mt-md-22 mb-22 mb-md-0"
                                  },
                                  [_vm._v(" Documents: Disputed Excel ")]
                                )
                              ]
                            : [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "d-block text-gray-900 mt-16 mt-md-22 mb-22 mb-md-0"
                                  },
                                  [
                                    _vm._v(
                                      " Selected document: " +
                                        _vm._s(
                                          _vm.form.file
                                            ? _vm.form.file.name
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                        ],
                        2
                      ),
                      _c("div", {
                        staticClass: "col mt-30",
                        staticStyle: { color: "red" },
                        domProps: { innerHTML: _vm._s(_vm.errorText) }
                      })
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "steps" }, [
                        _c("span", { staticClass: "fs18" }, [
                          _c("strong", [_vm._v("Steps to Follow:")])
                        ]),
                        _c("ul", { staticClass: "info-card mt-20" }, [
                          _c("li", [
                            _c("strong", { staticClass: "fs16" }, [
                              _vm._v("Step 1:")
                            ]),
                            _c("ol", { staticClass: "mt-20" }, [
                              _c("li", [
                                _vm._v(
                                  "Download the Dispute Sheet Format by clicking "
                                ),
                                _c("strong", [_vm._v('"Excel Sample"')]),
                                _vm._v(
                                  " and mention AWB and claimed weight (kgs) in the sheet. "
                                )
                              ]),
                              _c("li", [
                                _vm._v("Upload the sheet by "),
                                _c("strong", [_vm._v('"Choose File"')]),
                                _vm._v(" Option. ")
                              ])
                            ])
                          ]),
                          _c("li", [
                            _c("strong", { staticClass: "fs16" }, [
                              _vm._v("Step 2:")
                            ]),
                            _c("ol", { staticClass: "mt-20" }, [
                              _c("li", [
                                _vm._v(
                                  "Upload the supporting images (min.3) with scale showing L, B, H of the packed shipment by "
                                ),
                                _c("strong", [_vm._v('"Choose Images"')]),
                                _vm._v(" Option. ")
                              ]),
                              _c("li", [
                                _vm._v("Confirm the details in the "),
                                _c("strong", [_vm._v("Preview")]),
                                _vm._v(". ")
                              ]),
                              _c("li", [
                                _vm._v("Click on "),
                                _c("strong", [_vm._v('"Submit Dispute"')]),
                                _vm._v(" for final submission. ")
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "slide-left" } }, [
        _vm.toggleDispute
          ? _c("div", { staticClass: "container-fluid pt-70 pt-md-98" }, [
              _c("div", { staticClass: "card" }, [
                _c("div", { staticClass: "card__title" }, [
                  _c("div", { staticClass: "row col-md-12" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-5" },
                      [
                        _c("b-button", { on: { click: _vm.back } }, [
                          _vm._v("BACK TO STEP 1")
                        ])
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-md-7 my-auto" }, [
                      _c(
                        "span",
                        { staticClass: "fs20 lh24 fw500 align-center ml-50" },
                        [_vm._v(" Step 2 of 2 ")]
                      )
                    ])
                  ])
                ]),
                _c("div", { staticClass: "card__title first-title" }, [
                  _c("span", { staticClass: "fs18 lh24 fw500" }, [
                    _vm._v(
                      "Packed shipment images for Invoice Number : " +
                        _vm._s(_vm.ref_number)
                    )
                  ])
                ]),
                _c("div", { staticClass: "card__content" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-md-4 pb-md-0 pb-32" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "rounded border text-center d-inline-block w-sm-100"
                            },
                            [
                              _vm.uploadImages.length > 0
                                ? _c("img", {
                                    staticClass: "p-44",
                                    attrs: {
                                      src: require("@/assets/svg/document-uploaded.svg")
                                    }
                                  })
                                : _c("img", {
                                    staticClass: "p-44",
                                    attrs: {
                                      src: require("@/assets/svg/document.svg")
                                    }
                                  })
                            ]
                          )
                        ]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-md-8 d-flex flex-column justify-content-center"
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "d-block fs16 lh19 mb-12 fw500" },
                              [_vm._v(" Packet Shipment Images ")]
                            ),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "d-block fs14 lh20 mb-20 text-gray-600"
                              },
                              [
                                _c("strong", [_vm._v("Note:")]),
                                _vm._v(
                                  " Packed shipment images must be less than 5MB. "
                                )
                              ]
                            ),
                            _c("b-form-file", {
                              ref: "id-image-input",
                              staticClass: "d-none",
                              attrs: {
                                plain: "",
                                multiple: "",
                                accept: ".jpeg, .jpg, .png"
                              },
                              on: {
                                input: function($event) {
                                  return _vm.selectImages(_vm.form2.file)
                                }
                              },
                              model: {
                                value: _vm.form2.file,
                                callback: function($$v) {
                                  _vm.$set(_vm.form2, "file", $$v)
                                },
                                expression: "form2.file"
                              }
                            }),
                            _c(
                              "div",
                              { staticClass: "w-sm-100" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "w-sm-100 mr-0 mr-md-8",
                                    attrs: { variant: "tertiary" },
                                    on: {
                                      click: function($event) {
                                        return _vm.popImageInput(
                                          "id-image-input"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" Choose Image ")]
                                ),
                                _vm.uploadImages.length > 0
                                  ? _c(
                                      "b-button",
                                      {
                                        staticClass: "w-sm-100 mt-8 mt-md-0",
                                        attrs: { variant: "red" },
                                        on: { click: _vm.clearImages }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "icon-close-variant"
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _vm.uploadImages.length > 0
                        ? _c("div", { staticClass: "col mt-15" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.uploadImages[0]
                                    ? _vm.uploadImages[0].length
                                    : ""
                                ) +
                                " Files Uploaded "
                            )
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        { staticClass: "col mt-15" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "customer-address-field",
                                label: "Comments:",
                                "label-for": "customer-address",
                                description: _vm.form2.comments
                                  ? "Characters: " +
                                    _vm.form2.comments.length +
                                    " / " +
                                    this.maxLength
                                  : ""
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "input-group-addon" },
                                [
                                  _c("b-form-textarea", {
                                    staticClass: "sellerAddressLine",
                                    attrs: {
                                      required: "",
                                      id: "customer-address",
                                      type: "text",
                                      placeholder: "Dispute Comment...",
                                      maxlength: this.maxLength,
                                      rows: "14",
                                      "max-rows": "14"
                                    },
                                    model: {
                                      value: _vm.form2.comments,
                                      callback: function($$v) {
                                        _vm.$set(_vm.form2, "comments", $$v)
                                      },
                                      expression: "form2.comments"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col d-flex justify-content-end" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-45",
                              attrs: {
                                variant: "primary",
                                disabled: _vm.uploadImages.length < 1
                              },
                              on: { click: _vm.submitDispute }
                            },
                            [_vm._v("Submit")]
                          )
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c("div", { staticClass: "steps" }, [
                        _c("span", { staticClass: "fs18" }, [
                          _c("strong", [_vm._v("Steps to Follow:")])
                        ]),
                        _c("ul", { staticClass: "info-card mt-20" }, [
                          _c("li", [
                            _c("strong", { staticClass: "fs16" }, [
                              _vm._v("Step 1:")
                            ]),
                            _c("ol", { staticClass: "mt-20" }, [
                              _c("li", [
                                _vm._v(
                                  "Download the Dispute Sheet Format by clicking "
                                ),
                                _c("strong", [_vm._v('"Excel Sample"')]),
                                _vm._v(
                                  " and mention AWB and claimed weight (kgs) in the sheet. "
                                )
                              ]),
                              _c("li", [
                                _vm._v("Upload the sheet by "),
                                _c("strong", [_vm._v('"Choose File"')]),
                                _vm._v(" Option. ")
                              ])
                            ])
                          ]),
                          _c("li", [
                            _c("strong", { staticClass: "fs16" }, [
                              _vm._v("Step 2:")
                            ]),
                            _c("ol", { staticClass: "mt-20" }, [
                              _c("li", [
                                _vm._v(
                                  "Upload the supporting images (min.3) with scale showing L, B, H of the packed shipment by "
                                ),
                                _c("strong", [_vm._v('"Choose Images"')]),
                                _vm._v(" Option. ")
                              ]),
                              _c("li", [
                                _vm._v("Confirm the details in the "),
                                _c("strong", [_vm._v("Preview")]),
                                _vm._v(". ")
                              ]),
                              _c("li", [
                                _vm._v("Click on "),
                                _c("strong", [_vm._v('"Submit Dispute"')]),
                                _vm._v(" for final submission. ")
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ]),
              _c("div", { staticClass: "card mt-15 mb-30" }, [
                _c("div", { staticClass: "card__title" }, [
                  _c("div", { staticClass: "row col-md-12" }, [
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "span",
                        { staticClass: "fs20 lh24 fw500 align-center" },
                        [_vm._v(" Dispute Preview Table ")]
                      )
                    ]),
                    _c("div", { staticClass: "col-md-6" }, [
                      _c(
                        "span",
                        { staticClass: "fs20 lh24 fw500 align-center my-auto" },
                        [
                          _vm._v(
                            "Provisional Hold Amount : " + _vm._s(_vm.hold_amt)
                          )
                        ]
                      )
                    ])
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "card__content" },
                  [
                    _c("b-table", {
                      attrs: {
                        fields: _vm.fields,
                        items: _vm.items,
                        "sticky-header": "",
                        "no-border-collapse": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "cell(status)",
                            fn: function(data) {
                              return [
                                data.item.status
                                  ? _c("img", {
                                      staticClass: "tick",
                                      attrs: {
                                        src: require("@/assets/images/pending.png")
                                      }
                                    })
                                  : _vm._e(),
                                !data.item.status
                                  ? _c("img", {
                                      staticClass: "tick",
                                      attrs: {
                                        src: require("@/assets/images/no.png")
                                      }
                                    })
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3347003977
                      )
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "row col-md-12 pb-20" }, [
                  _c(
                    "div",
                    { staticClass: "col-md-5 ml-30" },
                    [
                      _c(
                        "b-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.toggleDispute = !_vm.toggleDispute
                            }
                          }
                        },
                        [_vm._v("BACK TO STEP 1")]
                      )
                    ],
                    1
                  ),
                  _c("div", { staticClass: "col-md-6 ml-55 my-auto" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "fs20 lh24 fw500 align-center my-auto ml-20"
                      },
                      [
                        _vm._v(
                          "Provisional Hold Amount : " + _vm._s(_vm.hold_amt)
                        )
                      ]
                    )
                  ])
                ])
              ])
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }