    <template>
    	<div class="calculator">
    		<AppTopbar
    		title="Billing Dispute"
    		buttonText="New Order"
    		buttonIconClass="icon-add"
    		buttonLink="/new"
    		/>

		<transition name="slide-right">
    		<div class="container-fluid pt-70 pt-md-98" v-if="!toggleDispute">
    			<div class="card">
    				<div class="card__title" style="justify-content:center !important">
    						<span class="fs20 lh24 fw500 align-center">
	    						Step 1 of 2
	    					</span>    					
    				</div>
    				<div class="card__title first-title">
    					<div>
    						<span class="fs18 lh24 fw500">Upload Dispute Detail for Invoice Number : {{ref_number}}</span>
    					</div>
    					<div class="text-right">
    						<b-button @click="downloadToast" variant="tertiary w-sm-100">
    							<i class="icon-download mr-11"></i>
    							EXCEL SAMPLE
    						</b-button>
    					</div>
    				</div>
    				<div class="card__content">
    					<div class="row">
    						<div class="col-md-6">
    							<div class="row">
    								<div class="col-md-4 pb-md-0 pb-32">
    									<div class="rounded border text-center d-inline-block w-sm-100">
    										<img v-if="form.file" src="@/assets/svg/document-uploaded.svg" class="p-44" />
    										<img v-else src="@/assets/svg/document.svg" class="p-44" />
    									</div>
    								</div>
    								<div class="col-md-8 d-flex flex-column justify-content-center">
    									<span class="d-block fs16 lh19 mb-12 fw500">
    										Disputed Excel
    									</span>
    									<span class="d-block fs14 lh20 mb-20 text-gray-600">
    										<strong>Note:</strong> In weight column please enter claimed weight.
    									</span>
    									<b-form-file
    									plain
    									accept=".xlsx"
    									ref="id-file-input"
    									v-model="form.file"
    									@input="uploadExcel"
    									class="d-none"
    									></b-form-file>
    									<div class="w-sm-100">
    										<b-button
    										@click="popInput('id-file-input')"
    										variant="tertiary"
    										class="w-sm-100 mr-0 mr-md-8"
    										>
    										Choose File
    									</b-button>
    							</div>
    						</div>
    					</div>

    					<div class="col">
    						<div id="preview"></div>
    						<template v-if="form.file == null">
    							<span
    							class="d-block text-gray-600 mt-16 mt-md-22 mb-22 mb-md-0"
    							>
    							Documents: Disputed Excel
    						</span>
	    					</template>
	    					<template v-else>
	    						<span
	    						class="d-block text-gray-900 mt-16 mt-md-22 mb-22 mb-md-0"
	    						>
	    						Selected document: {{ form.file ? form.file.name : "" }}
		    					</span>
		    				</template>
	    				</div>
	    				<div class="col mt-30" v-html="errorText" style="color:red">
	    					<!-- {{errorText}} -->
	    				</div>
    		</div>
    		<div class="col-md-6">
    			<div class="steps" >
    				<span class="fs18"><strong>Steps to Follow:</strong></span>
    				<ul class="info-card mt-20">
    					<li ><strong class="fs16">Step 1:</strong>
    						<ol class="mt-20">
    							<li>Download the Dispute Sheet Format by clicking <strong>"Excel Sample"</strong> and mention AWB and claimed weight (kgs) in the sheet. </li>
    							<li>Upload the sheet by <strong>"Choose File"</strong> Option. </li>
    						</ol>
    					</li>
    					<li ><strong class="fs16">Step 2:</strong>
    						<ol class="mt-20">
    							<li>Upload the supporting images (min.3) with scale showing L, B, H of the packed shipment by <strong>"Choose Images"</strong> Option. </li>
    							<li>Confirm the details in the <strong>Preview</strong>. </li>
    							<li>Click on <strong>"Submit Dispute"</strong> for final submission.  </li> 
    						</ol>
    					</li>
    				</ul>
    			</div>
    		</div>
    	</div>




    </div>
</div>

</div>
</transition>


<transition name="slide-left">
<div class="container-fluid pt-70 pt-md-98" v-if="toggleDispute">
	<div class="card">
		<div class="card__title" >
    					<div class="row col-md-12">
    						<div class="col-md-5"><b-button @click="back">BACK TO STEP 1</b-button></div>
	    					<div class="col-md-7 my-auto">
	    						<span class="fs20 lh24 fw500 align-center ml-50">
		    						Step 2 of 2
		    					</span>
	    					</div>
    					</div>
    					
    				</div>
		<div class="card__title first-title">
			<span class="fs18 lh24 fw500">Packed shipment images for Invoice Number : {{ref_number}}</span>
		</div>
		<div class="card__content">
			<div class="row">
				<div class="col-md-6">
					<div class="row">
						<div class="col-md-4 pb-md-0 pb-32">
							<div class="rounded border text-center d-inline-block w-sm-100">
								<img v-if="uploadImages.length > 0" src="@/assets/svg/document-uploaded.svg" class="p-44" />
								<img v-else src="@/assets/svg/document.svg" class="p-44" />
							</div>
						</div>
						<div class="col-md-8 d-flex flex-column justify-content-center">
							<span class="d-block fs16 lh19 mb-12 fw500">
								Packet Shipment Images
							</span>
							<span class="d-block fs14 lh20 mb-20 text-gray-600">
								<strong>Note:</strong> Packed shipment images must be less than 5MB.
							</span>
							<b-form-file
							plain
							multiple
							accept=".jpeg, .jpg, .png"
							ref="id-image-input"
							v-model="form2.file"
							class="d-none"
							@input="selectImages(form2.file)"
							></b-form-file>
							<div class="w-sm-100">
								<b-button
								@click="popImageInput('id-image-input')"
								variant="tertiary"
								class="w-sm-100 mr-0 mr-md-8"
								>
								Choose Image
							</b-button>
							<b-button
							v-if="uploadImages.length > 0"
							@click="clearImages"
							variant="red"
							class="w-sm-100 mt-8 mt-md-0"
							>
							<i class="icon-close-variant"></i>
						</b-button>
					</div>
				</div>
			</div>
			<div class="col mt-15" v-if="uploadImages.length > 0">
				{{uploadImages[0] ? uploadImages[0].length : ''}} Files Uploaded
			</div>
			<div class="col mt-15">
				<b-form-group
				id="customer-address-field"
				label="Comments:"
				label-for="customer-address"
				:description="
				form2.comments ?'Characters: ' + form2.comments.length + ' / ' + this.maxLength : '' 
				"
				>
				<div class="input-group-addon">
					<b-form-textarea
					required
					id="customer-address"
					type="text"
					class="sellerAddressLine"
					v-model="form2.comments"
					placeholder="Dispute Comment..."
					:maxlength="this.maxLength"
					rows="14"
					max-rows="14"
					></b-form-textarea>
				</div>
			</b-form-group>
		</div>
		<div class="col d-flex justify-content-end">
			<b-button variant="primary" :disabled="uploadImages.length < 1" @click="submitDispute" class="mr-45">Submit</b-button>
		</div>
	</div>
	<div class="col-md-6">
		<div class="steps" >
			<span class="fs18"><strong>Steps to Follow:</strong></span>
			<ul class="info-card mt-20">
				<li ><strong class="fs16">Step 1:</strong>
					<ol class="mt-20">
						<li>Download the Dispute Sheet Format by clicking <strong>"Excel Sample"</strong> and mention AWB and claimed weight (kgs) in the sheet. </li>
						<li>Upload the sheet by <strong>"Choose File"</strong> Option. </li>
					</ol>
				</li>
				<li ><strong class="fs16">Step 2:</strong>
					<ol class="mt-20">
						<li>Upload the supporting images (min.3) with scale showing L, B, H of the packed shipment by <strong>"Choose Images"</strong> Option. </li>
						<li>Confirm the details in the <strong>Preview</strong>. </li>
						<li>Click on <strong>"Submit Dispute"</strong> for final submission.  </li> 
					</ol>
				</li>
			</ul>
		</div>
	</div>
</div>

</div>




</div>

	<div class="card mt-15 mb-30">
		<div class="card__title" >
			<div class="row col-md-12">
				<div class="col-md-6">
					<span class="fs20 lh24 fw500 align-center">
						Dispute Preview Table
					</span>
				</div>
				<div class="col-md-6">
					<span class="fs20 lh24 fw500 align-center my-auto">Provisional Hold Amount : {{hold_amt}}</span>
				</div>
			</div>
			
		</div>
		<div class="card__content">
			<b-table :fields="fields" :items="items" sticky-header no-border-collapse>

				<template v-slot:cell(status)="data">
		            <img class="tick" src="@/assets/images/pending.png" v-if="data.item.status" />
                	<img class="tick" src="@/assets/images/no.png" v-if="!data.item.status" />
		         </template>
			</b-table>
		</div>
		<div class="row col-md-12 pb-20">
			<div class="col-md-5 ml-30"><b-button @click="toggleDispute = !toggleDispute">BACK TO STEP 1</b-button></div>
			<div class="col-md-6 ml-55 my-auto">
				<span class="fs20 lh24 fw500 align-center my-auto ml-20">Provisional Hold Amount : {{hold_amt}}</span>
			</div>
		</div>
	</div>
	
</div>
</transition>
</div>

</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import AppTopbar from "@/components/layout/AppTopbar.vue";
import { mapState } from "vuex";

export default {
	name: "Dispute",
	title: "Shyplite - Billing Dispute",
	data() {
		return {
			ref_number:'',
			fields: [
		        { key: "order_id", label: "ORDER-ID" ,thClass: "bg-grey",tdClass:"mid-align"},
		        { key: "awb", label: "AWB" ,thClass: "bg-grey",tdClass:"mid-align"},
		        { key: "bill_type", label: "BILL-TYPE" ,thClass: "bg-grey",tdClass:"mid-align"},
		        { key: "mode", label: "MODE" ,thClass: "bg-grey",tdClass:"mid-align"},
		        { key: "volumetric_wt", label: "VOLUMETRIC WT." ,thClass: "bg-grey",tdClass:"mid-align"},
		        { key: "declared_wt", label: "DECLARED WT." ,thClass: "bg-grey",tdClass:"mid-align"},
		        { key: "charged_wt", label: "CHARGED WT." ,thClass: "bg-grey",tdClass:"mid-align"},
		        { key: "claimed_wt", label: "CLAIMED WT." ,thClass: "bg-grey",tdClass:"mid-align"},
	        	{ key: "charged_amt", label: "CHARGED AMT." ,thClass: "bg-grey",tdClass:"mid-align"},
	        	{ key: "disputed_amt", label: "DISPUTED AMT." ,thClass: "bg-grey",tdClass:"mid-align"},
	        	{ key: "status", label: "STATUS" ,thClass: "bg-grey",tdClass:"mid-align"},
	        	{ key: "remark", label: "REMARK" ,thClass: "bg-grey",tdClass:"mid-align lastChild"},
		      ],
	      	items:[],
	      	form:{
				file:null
	      	},
			orderType: null,
			options: ["Prepaid", "Cod", "Reverse"],
			form:{},
			calcData:[],
			calcForm:[],
			autocalcForm:[],
			pincode:null,
			pincode1:null,
			totalValue:false,
			serviceTab:[],
			pricingTab:[],
			NCtable:false,
			uploadImages:[],
			maxLength:200,
			comments:'',
			toggleDispute:false,
			hold_amt:'',
			form2:{
				file:null
			},
			excelData:[],
			errorText:''
		};
	},
	components: {
		AppTopbar,
		Multiselect
	},
	computed:{
		isComplete() {
			if(this.form.orderType || this.form.orderType2){return true} else {return false}
		},
	...mapState(["user"])
},
mounted() {
	this.ref_number = this.$route.params.id
},
methods: {
	back() {
		this.toggleDispute = !this.toggleDispute
		this.form.file = null
	},
	async submitDispute() {
		this.form2['refNo'] = this.ref_number
		this.form2['totalVal'] = this.hold_amt
		this.form2['excelData'] = JSON.stringify(this.excelData).replace(/[\\']/g, '').replace(/\u0000/g, '').replace(/\//g, '')
		this.form2['disputeComment'] = this.form2.comments
		delete this.form2.comments
		if (this.form2.file.find(item => !(item.name.split('.').pop().includes('png') || item.name.split('.').pop().includes('jpeg') || item.name.split('.').pop().includes('jpg')))) {
			this.popToast('failed','Failed','Only PNG,JPG or JPEG file format is supported')
			return
		} else {
			// statement
		}
		let fd = new FormData();
        for (const key of Object.keys(this.form2)) {
        	if (key == 'file') {
        		this.form2[key].forEach(i => {
		    		fd.append(key, i);
        		});
        	}
        	else {
	    		fd.append(key, this.form2[key]);
        	}
        }

        try {
        	// const res = await this.axios.post('/billing/setdisputedetail',fd)
        	const res = await this.axios({method: 'post', url: '/billing/setdisputedetail', headers:{
        		'Content-Type': 'multipart/form-data'
        	}, data: fd});
        	if(res.data.status) {
        		this.popToast('booked','Booked',res.data.message)
        		setTimeout(() => {
					this.$router.push('/billing')
				},1000)
        	} else {
        		this.popToast('failed','Failed',res.data.error)
        	}
        } catch(e) {
        	this.popToast('failed','Failed',"Form Submission Failed!! Please Try again Later")
        	console.log(e);
        }
	},
	popInput(value) {
		this.$refs[value].$el.click();
	},
	popImageInput(value) {
		this.$refs[value].$el.click();
	},
	clearIdFile(value) {
		this.form.file = null
	},
	clearImages(){
		this.uploadImages = []
	},
	selectImages(images) {
		if (images.find(i=> i.size > 500000)) {
			this.popToast('failed','Failed',"File Size selected is greater than 5Mb!! Please Try Again")
		} else {
			this.uploadImages = []
			this.uploadImages.push(images)
			// statement
		}
	},
	async uploadExcel() {
		if(this.form.file) {
			this.items = []
			this.form['refNo'] = this.ref_number
			let fd = new FormData();
	        for (const key of Object.keys(this.form)) {
	          fd.append(key, this.form[key]);
	        }
	        try {
	        	const res = await this.axios.post('/billing/dispute',fd)
	        	if (res.data.isDetail) {
	        		this.toggleDispute = true
	        		this.form2.comments = ''
	        		this.form2.file = null
	        		this.uploadImages = []
	        		this.hold_amt = res.data.totalProvisionalHoldAmount
	        		this.excelData = res.data.excelData
	        		this.errorText = ''
	        		res.data.dispute.forEach((item,index)=> {
	        			this.items.push({
	        				order_id:item.orderID,
		        			awb:item.awb,
		        			bill_type:item.billType,
		        			mode:item.modeValue,
		        			volumetric_wt:item.volumetricWeight,
		        			declared_wt:item.declearWeight,
		        			charged_wt:item.chargedWeight,
		        			claimed_wt:item.weight,
		        			charged_amt:item.chargedAmount,
		        			disputed_amt:item.DisputedAmount,
		        			status:item.status,
		        			remark:item.remark
	        			})
	        		})
	        	} else {
	        		this.errorText = res.data.error
	        		this.form.file = null
	        	}
	        } catch(e) {
	        	this.form.file = null
	        	this.popToast('failed','Failed',"Excel Upload Failed!! Please Try again Later")
	        	console.error(e);
	        }
		}
	},
	downloadToast() {
		this.download('https://s3-ap-southeast-1.amazonaws.com/shyplitepublicassets/assets/uploadFormats/disputesample.xlsx','Dispute Sample')
	}
},
};
</script>

<style scoped type="text/scss">
/deep/ .b-table-sticky-header {
	max-height: unset !important;
}
.tick {
    height: 1.7rem;
    width: 1.8rem;
}
.card__content .info-card li:before {
	content: unset !important;
}
.first-title {
	justify-content: space-between !important;
}
.sellerAddressLine {
	height: 8rem !important;
	width: 34rem !important;
}
.table_dispute {
	background: white !important;
}
/deep/ .bg-grey {
	background-color: #f4f7fa !important;
}
/deep/ .mid-align {
	vertical-align: middle !important;
}
/deep/ .lastChild {
	max-width: 10rem !important;
}
</style>